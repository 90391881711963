/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
require("prismjs/themes/prism-okaidia.css")
require("prismjs/plugins/command-line/prism-command-line.css")
